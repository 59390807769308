import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby';

import Brand from '../client/models/Brand';
import Layout from '../client/components/Layout';
import Site from '../client/models/Site';
import { mapEdgesToNodes } from '../client/utils/utils';
import { userLocation } from '../client/utils/userLocation';

export const query = graphql`
  {
    allSanityBrand {
      edges {
        node {
          logo {
            asset {
              url
            }
          }
          name
          uid {
            current
          }
        }
      }
    }
    allSanityFofPage(filter: { site: { enabled: { eq: true } } }) {
      edges {
        node {
          id
          site {
            _id
            uid {
              current
            }
          }
        }
      }
    }
    allSanitySite(filter: { enabled: { eq: true } }) {
      edges {
        node {
          _id
          brand {
            _id
            name
            uid {
              current
            }
          }
          countryCode
          currencySymbol
          currencySymbolPlacement
          defaultSignupForm
          enabled
          id
          includeTaxes
          inventoryField {
            value
          }
          languagePrimary {
            title
            value
          }
          languageSecondary {
            title
            value
          }
          mirror
          mirrorSite {
            _id
          }
          originalPriceField {
            value
          }
          parentSite {
            id
            uid {
              current
            }
          }
          productChannel {
            value
          }
          productItemFilterField {
            value
          }
          quantitySoldField {
            value
          }
          sellPriceField {
            value
          }
          shopEnabled
          shopToken
          shopUrl
          socialLinks {
            type
            url
          }
          strings {
            articlePage__relatedArticle {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__title {
              languagePrimary
              languageSecondary
            }
            accountPage__orders__title {
              languagePrimary
              languageSecondary
            }
            accountPage__register__title {
              languagePrimary
              languageSecondary
            }
            accountPage__activate__title {
              languagePrimary
              languageSecondary
            }
            accountPage__reset__title {
              languagePrimary
              languageSecondary
            }
            accountPage__login__title {
              languagePrimary
              languageSecondary
            }
            accountPage__logout__title {
              languagePrimary
              languageSecondary
            }
            accountPage__login__username {
              languagePrimary
              languageSecondary
            }
            accountPage__login__password {
              languagePrimary
              languageSecondary
            }
            accountPage__login__login {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__informationTitle {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__informationSubmit {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__passwordResetText {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__passwordResetMessage {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editInformationFirstName {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editInformationLastName {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editInformationPhone {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editInformationEmail {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__preferencesTitle {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__preferencesSubmit {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editPreferencesPreferredSpecies {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editPreferencesPreferredWaterType {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__addressTitle {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__addressSubmit {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressCountry {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressFirstName {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressLastName {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressCompany {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressAddressOne {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressAddressTwo {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressCity {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressProvince {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressZip {
              languagePrimary
              languageSecondary
            }
            accountPage__profile__editAddressPhone {
              languagePrimary
              languageSecondary
            }
            accountPage__register__email {
              languagePrimary
              languageSecondary
            }
            accountPage__register__firstName {
              languagePrimary
              languageSecondary
            }
            accountPage__register__lastName {
              languagePrimary
              languageSecondary
            }
            accountPage__register__phone {
              languagePrimary
              languageSecondary
            }
            accountPage__register__password {
              languagePrimary
              languageSecondary
            }
            accountPage__register__passwordConfirm {
              languagePrimary
              languageSecondary
            }
            accountPage__register__acceptMarketing {
              languagePrimary
              languageSecondary
            }
            accountPage__register__signUp {
              languagePrimary
              languageSecondary
            }
            accountPage__activate__password {
              languagePrimary
              languageSecondary
            }
            accountPage__activate__passwordConfirm {
              languagePrimary
              languageSecondary
            }
            accountPage__activate__activate {
              languagePrimary
              languageSecondary
            }
            accountPage__reset__password {
              languagePrimary
              languageSecondary
            }
            accountPage__reset__passwordConfirm {
              languagePrimary
              languageSecondary
            }
            accountPage__reset__reset {
              languagePrimary
              languageSecondary
            }
            brandBar__freeShippingAchieved {
              languagePrimary
              languageSecondary
            }
            brandBar__freeShippingLimit {
              languagePrimary
              languageSecondary
            }
            brandBar__freeShippingRemaining {
              languagePrimary
              languageSecondary
            }
            cart__checkoutButton {
              languagePrimary
              languageSecondary
            }
            cart__continueShopping {
              languagePrimary
              languageSecondary
            }
            cart__lineItemRemove {
              languagePrimary
              languageSecondary
            }
            cart__noItems {
              languagePrimary
              languageSecondary
            }
            cart__title {
              languagePrimary
              languageSecondary
            }
            cart__total {
              languagePrimary
              languageSecondary
            }
            footer__emailPlaceholder {
              languagePrimary
              languageSecondary
            }
            footer__emailTab {
              languagePrimary
              languageSecondary
            }
            footer__smsPlaceholder {
              languagePrimary
              languageSecondary
            }
            footer__smsTab {
              languagePrimary
              languageSecondary
            }
            footer__subscribeFormTitle {
              languagePrimary
              languageSecondary
            }
            frontPage__title {
              languagePrimary
              languageSecondary
            }
            menu__brandsMenuTitle {
              languagePrimary
              languageSecondary
            }
            menu__regionsMenuTitle {
              languagePrimary
              languageSecondary
            }
            notifications__cookiesText {
              languagePrimary
              languageSecondary
            }
            notifications__cookiesAcceptButton {
              languagePrimary
              languageSecondary
            }
            notifications__cookiesReadMoreButton {
              languagePrimary
              languageSecondary
            }
            productCTA__addToCartButton {
              languagePrimary
              languageSecondary
            }
            productCTA__notAvailable {
              languagePrimary
              languageSecondary
            }
            productCTA__notAvailableRegion {
              languagePrimary
              languageSecondary
            }
            productCTA__notifyButton {
              languagePrimary
              languageSecondary
            }
            productCTA__notifyConsent {
              languagePrimary
              languageSecondary
            }
            productCTA__notifyEmailPlaceholder {
              languagePrimary
              languageSecondary
            }
            productCTA__notifyError {
              languagePrimary
              languageSecondary
            }
            productCTA__notifySuccess {
              languagePrimary
              languageSecondary
            }
            productGroup__sidebarCategoriesTitle {
              languagePrimary
              languageSecondary
            }
            productGroup__sidebarFiltersTitle {
              languagePrimary
              languageSecondary
            }
            productGroup__sidebarSearchTitle {
              languagePrimary
              languageSecondary
            }
            productGroup__sidebarSortingTitle {
              languagePrimary
              languageSecondary
            }
            productListItem__optionButton {
              languagePrimary
              languageSecondary
            }
            productListItem__optionButtonSelected {
              languagePrimary
              languageSecondary
            }
            productListItem__quickshopButton {
              languagePrimary
              languageSecondary
            }
            productList__loadMoreButton {
              languagePrimary
              languageSecondary
            }
            productPage__relatedProductsTitle {
              languagePrimary
              languageSecondary
            }
            productPage__sizeChartButton {
              languagePrimary
              languageSecondary
            }
            productPage__warningsTitle {
              languagePrimary
              languageSecondary
            }
            productVariantSelector__selectPlaceholder {
              languagePrimary
              languageSecondary
            }
            storeLocator__dealersAvailable {
              languagePrimary
              languageSecondary
            }
            storeLocator__distance {
              languagePrimary
              languageSecondary
            }
            storeLocator__noDealersAvailable {
              languagePrimary
              languageSecondary
            }
            storeLocator__noLocation {
              languagePrimary
              languageSecondary
            }
            storeLocator__storeLocatorTitle {
              languagePrimary
              languageSecondary
            }
            storeLocator__yourLocationTitle {
              languagePrimary
              languageSecondary
            }
            storeLocator__zoomIn {
              languagePrimary
              languageSecondary
            }
            subscribeForm__consent {
              languagePrimary
              languageSecondary
            }
            subscribeForm__error {
              languagePrimary
              languageSecondary
            }
            subscribeForm__subscribeButton {
              languagePrimary
              languageSecondary
            }
            subscribeForm__success {
              languagePrimary
              languageSecondary
            }
          }
          title
          type
          uid {
            current
          }
        }
      }
    }
  }
`;

class FoF extends Component {
  constructor(props) {
    super(props);

    this.resolveSite = this.resolveSite.bind(this);

    this.initialState = {
      site: null,
    };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.resolveSite();
  }

  // prettier-ignore
  async resolveSite() {
    const { location } = this.props;
    const { allSanityBrand, allSanitySite } = this.props.data;

    const brands = mapEdgesToNodes(allSanityBrand).map((brand) => new Brand(brand));
    const sites = mapEdgesToNodes(allSanitySite).map((site) => new Site(site));

    const defaultBrand = brands.find((brand) => brand.brandUID === process.env.GATSBY_SITE_BRAND);

    const pathParts = location.pathname.split('/').filter((part) => part);
    let brand = brands.find((brand) => brand.brandUID === pathParts[0]);
    let site = null;

    if (brand) {
      site = sites.find((site) => site.brandUID === brand.brandUID && site.siteUID === pathParts[1]);
    } else {
      brand = defaultBrand;
      site = sites.find((site) => site.brandUID === brand.brandUID && site.siteUID === pathParts[0]);
    }

    if (!site) {
      const locationSite = await userLocation(brand, null, sites);
      navigate(locationSite.path);
    }

    this.setState((prevState) => ({
      ...prevState,
      brand: brand,
      sites: sites,
      site: site,
    }));
  }

  render() {
    const { brand, sites, site } = this.state;
    if (!site) return null;

    return (
      <Layout
        brand={brand}
        site={site}
        sites={sites}
        siteUID={site.siteUID}
        {...this.props}
      >
        <article className="page fof">
          <h1>Nothing to see here.</h1>
        </article>
      </Layout>
    );
  }
}

export default FoF;
